<template>
    <div>
        <v-dialog v-model="abrir" :width="width">
            <v-card>
                <v-card-title class="gradiente">Confirme</v-card-title>
                <v-card-text>
                    <h3 style="color: gray">{{texto}}</h3>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn id="btn_cancelar" color="gray" @click="fechar()" aria-label="cancelar">{{textCancel}}</v-btn>
                    <v-btn id="btn_confirmar" color="green" :loading="show_loading" aria-label="ok">{{textOk}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
  /* eslint-disable */

  export default {
    name: 'VConfirm',
    props: {
      width: {default: '30%'},
      textOk: {default: 'Confirmar'},
      textCancel: {default: 'Fechar'},
      loading: {default: false},
    },
    data: () => ({
      texto: 'Mensagem',
      abrir: false,
    }),
    methods: {
      setTexto(texto) {
        this.texto = texto
        this.abrir = !this.abrir
        var _this = this
        if (this.abrir) {
          return new Promise((resolve, reject) => {
            try {
              var inter = setInterval(function() {
                var btn_confirmar = document.getElementById('btn_confirmar')
                if (btn_confirmar !== null) {
                  btn_confirmar.addEventListener('click', function() {
                    resolve()
                  })
                }

                clearInterval(inter)
              }, 200)

            } catch (e) {
              _this.close()
              reject(e)
            }
          })
        }

      },
      close() {
        this.abrir = false
        this.show_loading = false
      },
      fechar() {
        this.abrir = false
        this.show_loading = false
        this.$emit('cancel')
      },
    },
    components: {},
    computed: {
      show_loading: {
        get() {
          return this.loading
        },
        set() {
          return this.show_loading
        }
      },
    },
    created() {
    },
    mounted() {
    },
    watch: {}
  }
</script>
