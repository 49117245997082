/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Licenca {
  constructor () {
    this.licenca_id = uuidV4()
    this.cliente_software_id = ''
    this.dt_vencimento= ''
    this.dt_vencimento_parc= ''
    this.dt_geracao = ''
    this.tp_geracao = 'M'
    this.qtd_parc = ''
    this.observacoes = ''
    this.is_confianca = false
  }
}
