/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Cliente {
  constructor () {
    this.cliente_id = uuidV4()
    this.cnpj_cpf = ''
    this.razao = ''
    this.fantasia = ''
    this.contato = ''
    this.observacoes = ''
    this.desativado = false
    this.bloqueado = false
    this.rg_ie = ''
    this.telefone = ''
    this.whatsapp = ''
    this.mensalista_id = ''
    this.clientes_softwares = []
  }
}
