/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getBackupSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}
export const Backup1SCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['*']

  return filtro
}
export const BackupSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['cnpj_cpf', 'razao', 'fantasia', 'last_backup','st_backup']

    // filtro.join.addLeft('clientes_softwares','clientes_softwares.cliente_id','clientes.cliente_id')
    filtro.filters.addFilterRaw('last_backup notnull', null)
    filtro.addOrder('last_backup','asc')


  return filtro
}
