<template>
  <v-app id="app">
    <v-main class="backmain" >
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
  import AOS from 'aos'
  
  export default {
    name: 'App',
    created() {
      AOS.init()
    }
  }
</script>
