/* eslint-disable */
import {nao_autorizado, pegaDados} from '../../services/GenericAPI'

const url = '/analise';

const state = {
  all: []
};

const getters = {
  listaAll: (state) => {
    return state.all
  },


};

const actions = {
  async getPercentualBackup({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get_perc_backup', [])).data
      onSucesso(list)
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },
  async getPercentualLicenca({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get_perc_lic', [])).data
      onSucesso(list)
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },
  async getPercentualSoftware({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get_perc_soft', [])).data
      onSucesso(list)
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },
  async setAllList({commit},{onSucesso}) {
    try {
      const list = (await pegaDados(url+'/get')).data;
      commit('SET_ALL', list);
      onSucesso()
    } catch (error) {
    }

  },
};

const mutations = {
  SET_ALL(state, all) {
    state.all = all
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
