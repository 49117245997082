/* eslint-disable */
import {autentica, lista, pegaDados, pegaLista, processar, setarToken} from '../../services/GenericAPI'
import {Filtro, Where} from "../../models/filtro/filtro";
import {UsuarioSCH} from "../../search/UsuarioSCH"
import {Usuario} from "../../models/usuario";
import {getBaseSCH} from "../../search/BaseSCH";

const url = '/usuario'

const state = {
   usuario: new Usuario(),
   all: [],
   allList: [],
   lista_dash: [
      {item: {titulo_item: 'Cadastro 2', icon:'mdi-view-dashboard-edit-outline', grupo: []}},
   ],
   filtro: new Filtro()
}

const getters = {
   listaDash: (state) => {
      return state.lista_dash
   },

   listaUsuarios: (state) => {
      return state.all
   },
   listaAllUsuarios: (state) => {
      return state.allList
   },
   pegaUsuarioId: (state) => (id) => {
      let mun = state.all.find(usuario => usuario.usuario_id === id)
      return mun !== null ? mun.descricao : '** NÃO ENCONTRADO'
   },

   pegaUsuario: (state) => {
      return state.usuario
   },
   pegaFiltro: (state) => {
      return state.filtro
   }
}

const actions = {
   async getListaDash({commit}, {onSucesso, onErro}) {
      try {
         const list = (await pegaDados(url + '/lista_dash', state.filtro)).data
         commit('SET_LISTA_DASH', list)
         onSucesso()
      } catch (error) {
         onErro()
      }
   },


   searchItem({commit}, search) {
      state.filtro.filters.addSearch(['nome', 'email'],
       '%' + search + '%',
       'ilike'
      )
   },
   async getItem({commit}, {id, onSucesso, onErro}) {
      try {
         let filtro = getBaseSCH('usuario_id', id, '=');
         const list = (await pegaDados(url + '/get', filtro)).data;
         commit('SET_USUARIO', list.data[0]);
         onSucesso()
      } catch (error) {
         onErro()
      }
   },


   setFiltro({commit}, perPage) {
      commit('SET_FILTRO', UsuarioSCH(perPage))
   },
   setUsuario({commit}, usuario) {
      commit('SET_USUARIO', usuario)
   },

   async setAllUsuarios({commit}, {onSucesso, onErro}) {
      try {
         const list = (await pegaDados(url + '/get', state.filtro)).data
         commit('SET_USUARIOS', list)
         onSucesso()
      } catch (error) {
         onErro()
      }
   },
   async setAllListUsuarios({commit}, {onSucesso, onErro}) {
      try {
         const list = (await pegaDados('login/getAll',[])).data
         commit('SET_ALL_USUARIOS', list)
         onSucesso()
      } catch (error) {
         console.log('ERROR: ',error);
         onErro()
      }
   },
   async processarUsuario({commit}, {usuario, acao, onSucesso, onErro}) {
      try {
         const res = await processar(url +(acao==='gravar' ? '/commit' : '/delete'), usuario, acao)
         this.dispatch('geral/setMsgSuccess', res.data.message)
         onSucesso();
      } catch (error) {
         onErro()
         console.log('error: ',error)
         this.dispatch('geral/setObjMsgError', error.response.data)
      }
   },
   async autenticaUsuarios({commit}, {usuario, onSucesso, onErro}) {
      try {
         const res = (await autentica(url+'/auth', usuario))
         sessionStorage.setItem('usuario', res.data.usuario.nome)
         sessionStorage.setItem('usuario_id', res.data.usuario.usuario_id)
         setarToken(res.data.token, res.data.usuario.usuario_id)
         sessionStorage.setItem('token', res.data.token)
         sessionStorage.setItem('token_expiration', res.data.token_expiration * 60 * 1000)

         this.dispatch('token/cancelarToken')
         this.dispatch('token/start', null)
         onSucesso();
      } catch (error) {

         console.log(error);
         this.dispatch('geral/setObjMsgError', error.response.data)
         onErro()
      }
   },
}

const mutations = {
   SET_USUARIO(state, usuario) {
      state.usuario = usuario
   },
   SET_USUARIOS(state, usuarios) {
      state.all = usuarios
   },
   SET_ALL_USUARIOS(state, usuarios) {
      state.allList = usuarios
   },
   SET_FILTRO(state, filtro) {
      state.filtro = filtro
   },

/**/
   SET_LISTA_DASH(state, lista) {
      state.lista_dash = lista
   }
}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
